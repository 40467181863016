//@import "bootstrap/bootstrap";

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: myriad-pro, roboto, Geneva, Tahoma, sans-serif !important;

  background-image: radial-gradient(ellipse closest-side at center, #ff0815 0, #b80d0d 100%);
  background-color: #b80d0d;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.h-100 {
  height: 100%;
}

.text-transform {
  text-transform: none
}

.registration-center-container {
  display: flex;
  flex-direction: column;
}

.flexgrow {
  display: flex;
  flex: 1 0 auto;
}

.spinner_white>div>div {
  background-color: white;
}

.registration-center-link {
  letter-spacing: 0.4px;

  >a {
    text-decoration: none;
    color: #e2000f;
    border-bottom: 1px solid #e2000f;

    &:hover {
      color: #b6000f;
      border-bottom: 1px solid #b6000f;
    }
  }
}

.checkmark {
  margin-top: 2px;
}
